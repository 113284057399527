import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Seo } from "../../components/seo";
import { EntriesQueryInterface } from "../../types/entries";
import { LayoutDefault } from "../../layouts/default";

import { GridContainer } from "../../components/grid/content-blocks/container";
import { GridRow } from "../../components/grid/content-blocks/row";
import { Tag } from "../../components/tag";
import { BreadCrumbs } from "../../components/breadcrumbs";

interface TagSizingInterface {
  size: number;
}

const TagSizing = styled.span`
  span {
    font-size: calc(
      1rem +
        ${(props: TagSizingInterface) =>
          props.size === 1 ? 0 : props.size * 2}px
    );
  }
`;

const TagSearchWrapper = styled.div`
  margin: 0 0 1rem;
`;

const TagSearchLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: bold;
  color: #aaa;
  text-transform: uppercase;
  cursor: pointer;
`;
const TagSearchInput = styled.input`
  -webkit-appearance: none;
  border: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
`;

export const TagsPage = (props: EntriesQueryInterface) => {
  const { entries } = props?.data?.craft;

  type TagFilter = string;
  const initialTagFilter: TagFilter = "";
  const [tagFilter, setTagFilter] = useState(initialTagFilter);

  const handleTagFilter = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const { value } = target;
    setTagFilter(value);
  };
  // const schema: SchemaInterface = {
  //   type: 'BlogPosting',
  //   dateModified: dateUpdated,
  //   datePublished: postDate,
  //   name: title,
  //   headline: title,
  //   author,
  //   uri,
  // }
  type TagTitle = string;
  type TagSlug = string;
  type TagCount = number;

  type TagCountInfo = [TagTitle, TagSlug, TagCount];

  interface TagCounter {
    [key: string]: TagCountInfo;
  }

  const initTagCounter: TagCounter = {};

  const tagCountInfo = entries
    .filter((entry) => entry.entryTags.length)
    .reduce((tagCounter, entry) => {
      const { entryTags } = entry;
      entryTags.forEach((tag) => {
        if (!tagCounter[tag.id]) {
          tagCounter[tag.id] = [tag.title, tag.slug, 1];
        } else {
          const tagCount = tagCounter[tag.id][2];
          tagCounter[tag.id][2] = tagCount + 1;
        }
      });

      return tagCounter;
    }, initTagCounter);

  const tagCounts = Object.values(tagCountInfo)
    .filter(([tagTitle, ...rest]) => {
      if (!tagFilter) return true;

      return tagTitle.toLowerCase().indexOf(tagFilter.toLowerCase()) !== -1;
    })
    .sort((tagA, tagB) => {
      if (tagA[2] === tagB[2]) {
        return tagA[0] > tagB[0] ? 1 : -1;
      }
      return tagA[2] < tagB[2] ? 1 : -1;
    });

  const title = "All Tags";

  return (
    <LayoutDefault>
      <Seo title={title} />
      <GridContainer>
        <GridRow>
          <BreadCrumbs />
          <h1>{title}</h1>
          <TagSearchWrapper>
            <TagSearchLabel htmlFor="tag-search">Filter tags</TagSearchLabel>
            <TagSearchInput
              id="tag-search"
              placeholder="Enter text"
              onChange={handleTagFilter}
            />
          </TagSearchWrapper>
          <hr />
          {tagCounts.map(([title, slug, count]: TagCountInfo) => (
            <TagSizing key={slug} size={count}>
              <Tag url={`/tags/${slug}`}>
                {title} ({count})
              </Tag>
            </TagSizing>
          ))}
        </GridRow>
      </GridContainer>
    </LayoutDefault>
  );
};

export const query = graphql`
  query EntryTagsPageQuery {
    craft {
      entries(section: ["projects", "articles"]) {
        ... on craft_articles_stories_Entry {
          entryTags {
            id
            uid
            title
            slug
          }
        }
        ... on craft_projects_showcase_Entry {
          entryTags {
            id
            uid
            title
            slug
          }
        }
      }
    }
  }
`;

export default TagsPage;
